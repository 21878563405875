<template>
  <div class="partner-list">
    <div class="container">
      <div class="header" :class="{skeleton:!state.loaded.counts}">
        <div class="row">
          <div class="col-lg-4">
            <div class="subject">
              <span class="img" style="background-image: url(/assets/ico/page.partnership.handshake.svg)"></span>
              <div>파트너십 프로젝트</div>
            </div>
          </div>
          <ul class="counts tight col-lg-8">
            <li>
              <span class="title">파트너사</span>
              <div>
                <b class="color-point">{{ getCasualFormat(state.counts.partners) }}</b>
                <span class="font-sm"> 기관</span>
              </div>
            </li>
            <li>
              <span class="title">프로젝트</span>
              <div>
                <b class="color-point">{{ getCasualFormat(state.counts.projects) }}</b>
                <span class="font-sm"> 개</span>
              </div>
            </li>
            <li>
              <span class="title">참여인원</span>
              <div>
                <b class="color-point">{{ getCasualFormat(state.counts.investor) }}</b>
                <span class="font-sm"> 명</span>
              </div>
            </li>
            <li>
              <span class="title"> 모집금액</span>
              <div>
                <b class="color-point">{{ getCasualFormat(state.counts.totalAmt) }}</b>
                <span class="font-sm"> 원</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="article" :class="{skeleton: !state.loaded.projects.init }">
        <ul class="categories tight">
          <li :class="{active: state.project.tab === c.name}" v-for="(c, idx) in categories" :key="idx">
            <router-link :to="`/partnership/${c.name}`" :disabled="!state.loaded.projects.init">
              <span>{{ c.title }}</span>
            </router-link>
          </li>
        </ul>
        <div class="cards">
          <template v-if="state.loaded.projects.init">
            <ul class="tight clearfix" v-if="state.project.list.length">
              <li v-for="(p, idx) in state.project.list" :key="idx" :class="{skeleton:p.placeholder}">
                <PartnerCard
                    :link="$store.getters.partnerCardLink(p.contestSeq, p.contestType)"
                    :contestSeq="p.contestSeq"
                    :thumbImagePath="p.thumbImagePath"
                    :thumbImageSaveName="p.thumbImageSaveName"
                    :partnershipName="p.partnershipName"
                    :contestName="p.contestName"
                    :contestExplanationText="p.contestExplanationText"
                    :projectCount="p.projectCount"
                    :sumAmt="p.sumAmt"
                    :progressOrder="p.progressOrder"
                />
              </li>
            </ul>
            <div class="pt-30 pb-30" v-else>
              <NoData/>
            </div>
            <ul class="tight clearfix" v-if="!state.loaded.projects.more">
              <li v-for="i in 3" :key="i">
                <PartnerCard
                    link=""
                    :skeleton="true"
                    :contestSeq="0"
                    partnershipName="Wait a moment"
                    contestName="Wait a moment"
                    contestExplanationText="Wait a moment"
                    :rewardProjectCount="0"
                    :rewardProjectSumAmt="0"
                />
              </li>
            </ul>
            <div class="more" v-if="state.loaded.projects.init && state.project.list.length < state.project.count">
              <button class="btn btn-default font-sm" @click="load()" :disabled="!state.loaded.projects.more">+ 더 보기</button>
            </div>
          </template>
          <ul class="tight clearfix" v-else>
            <li v-for="i in 3" :key="i">
              <PartnerCard
                  link=""
                  :skeleton="true"
                  :contestSeq="0"
                  partnershipName="Wait a moment"
                  contestName="Wait a moment"
                  contestExplanationText="Wait a moment"
                  :rewardProjectCount="0"
                  :rewardProjectSumAmt="0"
              />
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import store from "@/scripts/store";
import router from "@/scripts/router";
import PartnerCard from "@/components/PartnerCard";
import mixin from "@/scripts/mixin";
import NoData from "@/components/NoData.vue";
import lib from "@/scripts/lib";

function Component(initialize) {
  this.name = "pagePartnerList";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {NoData, PartnerCard},
  setup() {
    const component = new Component(() => {
      const tab = router.app.$route.params.tab;
      let documentTitle = "파트너십";

      switch (tab) {
        case "partnerlist":
          documentTitle = "펀딩대회";
          break;

        case "privatelist":
          documentTitle = "전용관";
          break;

        case "mocklist":
          documentTitle = "모의크라우드펀딩";
          break;
      }

      store.dispatch("setDocumentTitle", documentTitle);

      state.project.tab = tab;
      state.loaded.counts = false;

      http.get("/api/partnerships/main", undefined, {cache: true}).then(({data}) => {
        state.loaded.counts = true;
        state.counts = {
          partners: data.body.partnerShipTotalCnt,
          projects: Number(data.body.contestTotalInfo.projectCount),
          investor: Number(data.body.contestTotalInfo.investorCount),
          totalAmt: Number(data.body.contestTotalInfo.totalAmt),
        };

        load(true);
      });
    });

    const state = reactive({
      loaded: {
        counts: false,
        projects: {
          init: false,
          more: true
        },
      },
      args: {
        pageIndex: 1,
        pageSize: 5,
        pageUnit: 12
      },
      counts: {
        partners: 0,
        projects: 0,
        investor: 0,
        totalAmt: 0
      },
      project: {
        tab: "",
        count: 0,
        list: []
      }
    });

    const categories = [
      {name: "partnerlist", title: "펀딩대회", type: "PARTNER"},
      {name: "privatelist", title: "전용관", type: "EXCLUSIVE"},
      {name: "mocklist", title: "모의투자", type: "SIMULATION"},
    ];

    const load = (init) => {
      let contestType = categories.find(c => state.project.tab === c.name)?.type;

      if (init) {
        state.loaded.projects.init = false;
        state.loaded.projects.more = true;
        state.args.pageIndex = 1;
      } else {
        state.loaded.projects.more = false;
        state.args.pageIndex += 1;
      }

      http.get(`/api/partnerships/${contestType}/list`, state.args, {cache: true}).then(({data}) => {
        if (init) {
          state.loaded.projects.init = true;
        } else {
          state.loaded.projects.more = true;
        }

        if (init) {
          state.project.list = data.body.partnerList;
          state.project.count = data.body.partnerListTotCnt;
        } else {
          for (let p of data.body.partnerList) {
            state.project.list.push(p);
          }
        }
      });
    };

    const getCasualFormat = (val) => {
      const value = val?.toString();
      const num = window.Number(val);

      if (!val || isNaN(num)) {
        return val;
      }

      if (num < 10000) {
        return lib.getNumberFormat(value);
      } else if (num < 100000) {
        let tail = lib.getNumberFormat(value.slice(value.length - 4, value.length - 3));

        return `${lib.getNumberFormat(value.slice(0, value.length - 4))}만${tail !== "0" ? " " + tail + "천+" : "+"}`;
      } else if (num < 100000000) {
        return `${lib.getNumberFormat(value.slice(0, value.length - 4))}만+`;
      } else {
        let tail = lib.getNumberFormat(value.slice(value.length - 8, value.length - 4));

        return `${lib.getNumberFormat(value.slice(0, value.length - 8))}억${tail !== "0" ? " " + tail + "만+" : "+"}`;
      }
    };

    return {component, state, categories, load, getCasualFormat};
  }
});
</script>

<style lang="scss" scoped>
.partner-list {
  > .container {
    .header {
      margin: $px62 0 $px25 0;

      > .row {
        align-items: center;
      }

      .title {
        line-height: 1.6;
        font-weight: 400;
        margin-bottom: $px47;
      }

      .subject {
        font-size: $px18;
        font-weight: 500;
        position: relative;
        text-align: center;

        .img {
          width: $px50;
          height: $px50;
          margin-bottom: $px10;
        }
      }

      .counts {
        display: flex;
        gap: $px48;
        padding: $px16;

        li {
          display: flex;
          flex-direction: column;
          white-space: nowrap;

          .title {
            margin: 0;
            font-size: $px14;
            font-weight: 500;
            color: $colorSecondary;
          }

          b {
            font-size: $px18;
          }
        }
      }
    }

    .article {
      .categories {
        margin: 0;

        li {
          display: inline-block;
          padding: $px15;

          a {
            display: inline-block;
            padding: $px5;
            text-decoration: none;
            border-bottom: $px1 solid transparent;

            &:hover {
              font-weight: 500;
            }
          }

          &.active {
            a {
              font-weight: 600;
              color: $colorPoint;
              border-bottom-color: $colorPoint;
              padding-bottom: $px5;
            }
          }
        }
      }

      .cards {
        font-size: 0;
        padding: $px10 0 $px40 0;
        margin: 0 $px-15;

        ul {
          padding: 0 15px;
          display: flex;
          flex-wrap: wrap;
          gap: $px24;

          li {
            display: inline-block;
            width: calc((100% - ($px24 * 2)) / 3);
          }
        }

        .more {
          padding: $px50 $px15 $px20 $px15;
          text-align: center;

          button {
            padding: $px13 $px20;
          }
        }
      }
    }
  }

  > .container {
    .header.skeleton {
      .subject {
        .img, > div {
          @include skeleton;
        }
      }

      .counts li {
        @include skeleton;

        span, b {
          color: transparent !important;
        }
      }
    }

    .article.skeleton {
      .categories li a {
        @include skeleton;
      }
    }
  }

  @media(min-width: 992px) {
    > .container .article .categories li:first-child {
      padding-left: 0;
    }
  }

  @media(max-width: 991px) {
    > .container {
      .header {
        padding: 0 $px15;
        margin-top: $px36;

        .counts {
          flex-direction: column;
          gap: $px8;

          li {
            justify-content: space-between;
            flex-direction: row;

            .title {
              font-size: $px14;
              font-weight: 400;
            }

            b {
              font-size: $px16;
            }
          }
        }
      }

      .article {
        .categories {
          text-align: center;
        }

        .cards ul li {
          width: 100%;
        }
      }
    }
  }
}
</style>